import React from 'react';
import PropTypes from 'prop-types';
import "./styles/OrderTotals.scss";

const OrderTotals = ({order}) => {
    return (
        <table className="table order-totals">
            <colgroup>
                <col className="col-md-6 col-xs-6"/>
                <col className="col-md-6 col-xs-6"/>
            </colgroup>
            <tbody>
            <tr className="order-totals__section order-totals__section--subtotal">
                <td>SUBTOTAL</td>
                <td/>
                <td className="text-right">${order.sub_total}</td>
            </tr>
            {
                (order.promotions && order.promotions.length > 0 && parseFloat(order.discount_fee) > 0) &&
                order.promotions.map((promotion) => (
                    <tr key={promotion.id} className="order-totals__section order-totals__section--discount">
                        <td>{promotion.name}</td>
                        <td/>
                        <td className="text-right">-${promotion.total}</td>
                    </tr>
                ))
            }
            <tr className="order-totals__section order-totals__section--shipping">
                <td>SHIPPING</td>
                <td/>
                <td className="text-right">${order.shipping_fee}</td>
            </tr>
            <tr className="order-totals__section order-totals__section--taxes">
                <td>TAXES</td>
                <td/>
                <td className="text-right">${order.sale_tax}</td>
            </tr>
            <tr className="order-totals__section order-totals__section--total">
                <td/>
                <td>TOTAL</td>
                <td className="text-right">${order.total}</td>
            </tr>
            </tbody>
        </table>
    );
}

OrderTotals.propTypes = {
  order: PropTypes.object.isRequired
};

export default OrderTotals;
